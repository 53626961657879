<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="店铺名称:">
                    <el-input class="s-input" v-model="queryParams.name" placeholder="请输入店铺名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="用户id:">
                    <el-input class="s-input" v-model="queryParams.uid" placeholder="请输入用户id" clearable></el-input>
                </el-form-item>
                <el-form-item label="加盟商:">
                    <el-select v-model="queryParams.own" placeholder="请选择加盟商" clearable @change="changeQuery">
                        <el-option label="总部" :value="0"></el-option>
                        <el-option label="自营" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择状态" clearable @change="changeQuery">
                        <el-option label="下线" :value="1"></el-option>
                        <el-option label="上线" :value="2"></el-option>
                    </el-select>
                </el-form-item>

                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <!-- <div class="action">
                <el-button type="primary" @click="add()">新增</el-button>
            </div>-->
            <!-- 表格内容 -->
            <el-table v-loading="loading" element-loading-text="数据加载中" stripe border :data="pageInfo.list" style="margin-top: 25px;">
                <el-table-column label="id" prop="id" width="100" align="center"></el-table-column>
                <el-table-column label="用户id" prop="uid" width="100" align="center"></el-table-column>
                <el-table-column label="店铺名称" prop="name" width="220" align="center"></el-table-column>
                <el-table-column label="图片" prop="img" align="center" width="80">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img1')" style="height:23px;width:30px" :class="`img1column${scope.$index}`" class="table-td-thumb img1" :src="scope.row.img" :preview-src-list="[scope.row.img]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="店铺logo" prop="logo" align="center" width="80">
                    <template slot-scope="scope">
                        <el-image @click="showBigImg(scope.$index,'img2')" style="height:23px;width:30px" :class="`img2column${scope.$index}`" class="table-td-thumb img2" :src="scope.row.logo" :preview-src-list="[scope.row.logo]">
                            <div slot="error" class="image-slot">
                                <el-image :src="defaultImg" class="center_image"></el-image>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column label="描述" prop="desc" align="center"></el-table-column>
                <el-table-column label="营业执照" prop="license" width="180" align="center"></el-table-column>
                <el-table-column label="过期时间" prop="expiration" width="200" align="center"></el-table-column>
                <el-table-column label="店铺状态" prop="state" width="100" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.state === 1">下线</el-tag>
                        <el-tag type="success" v-if="scope.row.state === 2">上线</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="加盟商" prop="own" width="100" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.own === 0">总部</el-tag>
                        <el-tag type="primary" v-if="scope.row.own === 1">自营</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="150" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 编辑店铺管理-->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" class="dialog_box">
            <el-form class="form-box" ref="form" v-bind:rules="rules" v-bind:model="form" v-loading.body="loading" label-position="left" label-suffix=":" label-width="100px" size="large" element-loading-text="上传中">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="desc">
                    <el-input v-model="form.desc"></el-input>
                </el-form-item>
                <!-- 图片上传 -->
                <el-form-item label="头像" prop="img">
                    <el-input v-model="form.img" placeholder="请输入头像链接"></el-input>
                    <el-button @click.prevent="choiceImg('img')">选择图片</el-button>
                    <el-upload class="avatar-uploader up img2" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
                        <img v-if="form.img" :src="form.img" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸100*100像素</span>
                </el-form-item>
                <el-form-item label="logo图片" prop="logo">
                    <el-input v-model="form.logo" placeholder="请输入logo图片链接"></el-input>
                    <el-button @click.prevent="choiceImg('logo')">选择图片</el-button>
                    <el-upload class="avatar-uploader up img1" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleLogoSuccess" :before-upload="beforeLogoUpload" name="file" :headers="header">
                        <img v-if="form.logo" :src="form.logo" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <span class="span_gray">建议尺寸100*100像素</span>
                </el-form-item>
                <el-form-item label="营业执照" prop="license">
                    <el-input v-model="form.license"></el-input>
                </el-form-item>
                <el-form-item label="过期时间" prop="expiration">
                    <el-date-picker v-model="form.expiration" :picker-options="pickerOptions" type="date" placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="加盟商">
                    <el-radio-group v-model="form.own">
                        <el-radio :label="0">总部</el-radio>
                        <el-radio :label="1">自营</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">提 交</el-button>
            </div>
        </el-dialog>

        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb'
import Pagination from '@/components/Pagination'
import { request } from '@/common/request'
import { deal } from '@/common/main'
import loadEvents from '@/utils/loading'
let loads = new loadEvents();
import Picture from '@/components/Picture';
import {IMAGE_UPLOAD_PRO_URL} from "../../common/const";
export default {
    name: 'StoreInfo',
    components: {
        Breadcrumb,
        Pagination,
        Picture
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL:IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            text: 'image',
            defaultImg: require('../../assets/img/zhan.jpeg'),
            avatar: '../../assets/img/img.jpg',
            menuList: ['客户', '店铺管理'],
            loading: false,
            confirmVisible: false,
            confirmContent: '',
            imageUrl: '',
            header: {},
            queryParams: {
                page: 1,
                pagesize: 10,
                countType: '',
                num: '',
            },
            pageInfo: {},
            brandList: [],
            addVisible: false,
            addVisibles: false,
            title: '添加店铺',
            rules: {
                name: [{
                    required: true,
                    message: '名称不能为空',
                    trigger: 'blur'
                }],
                desc: [{
                    required: true,
                    message: '描述不能为空',
                    trigger: 'blur'
                }],
                img: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                logo: [
                    { validator: deal.checkImage, trigger: ['blur', 'change'], required: true, }
                ],
                license: [{
                    required: true,
                    message: '营业执照不能为空',
                    trigger: 'blur'
                }],
                expiration: [{
                    required: true,
                    message: '过期时间不能为空',
                    trigger: 'blur'
                }],

                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
                own: [{
                    required: true,
                    message: '加盟商不能为空',
                    trigger: 'blur'
                }],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            mode: 'add',
            form: {
                countType: 1,
                num: 1,
                countType: 1,
                ty: 1,
            },
        }
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
        // this.getBrandList()
    },
    methods: {
        toggleImg(val) { // 关闭 img对话框
            this.ImgVisible = val;

        },
        choiceImg(text) {
            this.text = text
            this.ImgVisible = true
        },
        getImg(url) {// 获取选择的img
            this.$set(this.form, this.text, url);
        },
        // 查看大图
        showBigImg(index, node) {
            this.$nextTick(function () {
                let shows = document.getElementsByClassName(`${node}column${index}`)
                let show = shows[0].getElementsByClassName('el-image-viewer__wrapper')
                show[0].style['display'] = 'block'
                let dom = show[0].getElementsByClassName('el-image-viewer__mask')
                dom[0].addEventListener('click', function () {
                    show[0].style['display'] = 'none'
                })
            })
        },
        //状态
        state(row, state) {
            if (state == 0) {
                this.confirmContent = '确定是否上线?';
                this.form = Object.assign({}, row);
                this.form.state = state;
                this.form.etime = deal.timestamp(this.form.etime);
                this.form.stime = deal.timestamp(this.form.stime);
                this.confirmVisible = true;
            } else {
                this.confirmContent = '确定是否下线?';
                this.form = Object.assign({}, row);
                this.form.state = state;
                this.form.etime = deal.timestamp(this.form.etime);
                this.form.stime = deal.timestamp(this.form.stime);
                this.confirmVisible = true;
            }
        },

        loadData() {
            this.loading = true;
            let _this = this
            //店铺列表
            request.get('/account/merchant/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.expiration = deal.timestamp(val.expiration, 'unix');
                        if (val.logo.length < 1) {
                            val.logo = _this.avatar
                        }
                        if (val.img.length < 1) {
                            val.img = _this.avatar
                        }
                    })

                } else {
                    this.$message.error(ret.msg);
                }
            });
            console.log(3333333333)
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1
            this.loadData()
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val
            this.loadData()
        },
        refreshPageNum(val) {
            this.queryParams.page = val
            this.loadData()
        },
        //图片上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'img', res.data.uri);
            }
        },
        handleLogoSuccess(res, file) {
            if (res.code == 1) {
                loads.close()
                this.$set(this.form, 'logo', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            loads.open(`.img2 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this, 300)
        },
        beforeLogoUpload(file) {
            loads.open(`.img1 .el-upload`, '上传中')
            const _this = this
            return deal.imageFormat(file, _this, 300)
        },
        // add (id, index) {
        //     this.mode = 'add';
        //     this.form = { num: 1, countType: 1, ty: 1 }
        //     this.addVisible = true
        //     this.form = {
        //         state: 1,
        //         ty: 1
        //     };
        //     this.addVisible = true
        // },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑店铺';
            const item = this.pageInfo.list[index];
            //处理时间
            this.form = Object.assign({}, item);
            console.log(1111111, this.form)
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        delete(id, index) {

        },
        changeCount(num) {
            this.form.num = num
        },
        changeDatePicker: function () {
            let vue = this
            if (vue.pickerDatad != null) {
                vue.form.stime = vue.pickerDatad[0]
                vue.form.etime = vue.pickerDatad[1]
            } else {
                vue.form.stime = null
                vue.form.etime = null
            }
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        //   this.form.num = this.form.countType == 1 ? 1 : this.form.num;
                        // this.form.brand_ids = this.form.brand_id;
                        var url = '/account/merchant/edit';
                        this.form.expiration = deal.timestamp(this.form.expiration);
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.addVisibles = false;
                                this.loadData();
                                this.$message.success('编辑成功');
                                this.$refs.form.clearValidate()
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$refs.form.clearValidate();
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/account/merchant/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
}

</script>

<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}

/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

/* 图片样式 */
.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.dialog_box .span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}
/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 20px;
}
</style>
